<template>
  <div class="view-forgot-password mb-5">
    <div class="container">
      <div class="row pt-lg-5">
        <div class="col-md-6 col-lg-5 pt-lg-5 mx-auto">
          <form @submit.prevent="submit">
            <div class="card-body pt-0 pb-5 px-0 py-lg-5 px-lg-5">
              <div class="text-center">
                <img
                  width="181"
                  height="97.27"
                  src="@/assets/images/logo.png"
                />
              </div>
              <h2 class="text-center my-2 my-lg-4 text-uppercase">
                Esqueceu sua senha?
              </h2>
              <p class="text-center">
                Sem problemas! Insira o email da sua conta no BORA CONECTA que
                enviaremos um link para redefinir sua senha.
              </p>
              <form-group
                v-model="email"
                :is-login="true"
                id="email"
                type="email"
                label="Email"
                placeholder="Digite seu e-mail"
                :errors="errors.email"
                :show-error-message="false"
                @change="resetErrors"
              />
              <div class="text-center py-3 py-lg-4">
                <button
                  :loading="showLoading"
                  type="submit"
                  :disabled="!isValidFields"
                  class="btn btn-primary btn-block"
                >
                  <loading :show="showLoading">
                    Enviar
                  </loading>
                </button>
              </div>
              <p class="text-center mb-3 mb-lg-5">
                <router-link class="btn btn-sm" :to="{ name: 'login' }">
                  <span class="text-primary font-weight-bold">
                    Voltar para o login
                  </span>
                </router-link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <confirmation
        iconType="check"
        title="Concluído!"
        v-model="success"
        confirmButtonClass="btn btn-primary"
        confirmButtonText="Fechar"
        text="Enviamos um link para o seu e-mail."
        @confirm="onSuccess"
      />
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { authMixin } from "@/mixins";

export default {
  name: "login",
  mixins: [authMixin],
  data() {
    return {
      email: "",
      errors: {
        email: [],
        non_field_errors: []
      },
      showLoading: false,
      forgotDialog: false,
      isValidFields: false,
      success: false
    };
  },
  computed: {
    errorMsg() {
      return (
        get(this.errors, "email[0]") ||
        get(this.errors, "password[0]") ||
        get(this.errors, "non_field_errors[0]")
      );
    }
  },
  watch: {
    password() {
      this.validateFields();
    },
    email() {
      this.validateFields();
    }
  },
  methods: {
    validateFields() {
      const isValidEmail = !!this.email && /\S+@\S+\.\S+/.test(this.email);
      this.isValidFields = isValidEmail;
    },
    resetErrors() {
      this.errors = {
        email: [],
        non_field_errors: []
      };
    },
    submit() {
      this.showLoading = true;
      this.resetErrors();
      this.$store
        .dispatch("user/forgotPassword", this.email)
        .then(data => {
          this.success = true;
          this.$message.success(data.message);
        })
        .catch(this.handleErrors)
        .finally(this.handleFinally);
    },
    onSuccess() {
      this.success = true;
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-forgot-password {
  width: 100%;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #fff;
  }
  .btn-sm {
    font-size: 14px;
    font-weight: 900;
  }
}
</style>
