export default {
  methods: {
    handleErrors(error) {
      const data = error?.response?.data;
      if (data?.email?.length) {
        this.$message.error(`Email: ${data.email.join(",")}`);
      }

      if (data?.password?.length) {
        this.$message.error(`Password: ${data.password.join(",")}`);
      }

      if (data?.token?.length) {
        this.$message.error(`Token: ${data.token.join(",")}`);
      }

      if (data?.message) {
        this.$message.error(
          typeof data.message == "string"
            ? data.message
            : data.message.join(",")
        );
      }

      if (data?.error === "invalid_grant") {
        this.errors.email = ["E-mail ou senha inválidos"];
        this.errors.password = ["E-mail ou senha inválidos"];
      } else {
        this.errors.non_field_errors = [data?.error_description];
        data?.non_field_errors?.[0]
          ? this.$message.error(data.non_field_errors?.[0])
          : "";
      }
      this.showLoading = false;
    }
  },
  handleFinally() {
    this.showLoading = false;
  }
};
