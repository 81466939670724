export default {
  data() {
    return {
      id_name_map: {},
      paramsFilter: {
        category_id__in: "",
        status__in: "",
        status: "",
        name__icontains: "",
        title__contains: "",
        order_by: "",
        q: "",
        city: "",
        state: "",
        page: this.$route.query.page || 1,
        related_product: ""
      },
      oldParamFilter: null
    };
  },
  methods: {
    updateNameMap() {
      const new_id_name_map = {};
      for (let i in this.categories) {
        new_id_name_map[this.categories[i].id] = this.categories[i].name;
      }
      this.id_name_map = new_id_name_map;
    },
    hasToResetPageFilter(newVal, oldVal) {
      const cloneNewVal = Object.assign({}, newVal);
      const cloneOldVal = Object.assign({}, oldVal);

      delete cloneNewVal.page;
      delete cloneOldVal.page;

      const changedFiltersAsidePage =
        { ...cloneNewVal, ...cloneOldVal } != cloneOldVal;
      const isNewPageDifZero = newVal.page !== 0;
      const isChanginPagesFilter = newVal.page != oldVal?.page;

      return [changedFiltersAsidePage, isNewPageDifZero, !isChanginPagesFilter];
    },
    concatList(list) {
      return list.join(",");
    }
  }
};
